exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bioestimuladorescolageno-js": () => import("./../../../src/pages/bioestimuladorescolageno.js" /* webpackChunkName: "component---src-pages-bioestimuladorescolageno-js" */),
  "component---src-pages-bioimpedancia-js": () => import("./../../../src/pages/bioimpedancia.js" /* webpackChunkName: "component---src-pages-bioimpedancia-js" */),
  "component---src-pages-biorressonancia-js": () => import("./../../../src/pages/biorressonancia.js" /* webpackChunkName: "component---src-pages-biorressonancia-js" */),
  "component---src-pages-caroline-js": () => import("./../../../src/pages/caroline.js" /* webpackChunkName: "component---src-pages-caroline-js" */),
  "component---src-pages-consultanutricionista-js": () => import("./../../../src/pages/consultanutricionista.js" /* webpackChunkName: "component---src-pages-consultanutricionista-js" */),
  "component---src-pages-consultanutrologo-js": () => import("./../../../src/pages/consultanutrologo.js" /* webpackChunkName: "component---src-pages-consultanutrologo-js" */),
  "component---src-pages-deisy-js": () => import("./../../../src/pages/deisy.js" /* webpackChunkName: "component---src-pages-deisy-js" */),
  "component---src-pages-detoxvilla-js": () => import("./../../../src/pages/detoxvilla.js" /* webpackChunkName: "component---src-pages-detoxvilla-js" */),
  "component---src-pages-drenagemlinfatica-js": () => import("./../../../src/pages/drenagemlinfatica.js" /* webpackChunkName: "component---src-pages-drenagemlinfatica-js" */),
  "component---src-pages-drroberto-js": () => import("./../../../src/pages/drroberto.js" /* webpackChunkName: "component---src-pages-drroberto-js" */),
  "component---src-pages-fiosdepdo-js": () => import("./../../../src/pages/fiosdepdo.js" /* webpackChunkName: "component---src-pages-fiosdepdo-js" */),
  "component---src-pages-footdetox-js": () => import("./../../../src/pages/footdetox.js" /* webpackChunkName: "component---src-pages-footdetox-js" */),
  "component---src-pages-harmonizacaodegluteos-js": () => import("./../../../src/pages/harmonizacaodegluteos.js" /* webpackChunkName: "component---src-pages-harmonizacaodegluteos-js" */),
  "component---src-pages-harmonizacaofacial-js": () => import("./../../../src/pages/harmonizacaofacial.js" /* webpackChunkName: "component---src-pages-harmonizacaofacial-js" */),
  "component---src-pages-hidragloss-js": () => import("./../../../src/pages/hidragloss.js" /* webpackChunkName: "component---src-pages-hidragloss-js" */),
  "component---src-pages-hormoniossalivares-js": () => import("./../../../src/pages/hormoniossalivares.js" /* webpackChunkName: "component---src-pages-hormoniossalivares-js" */),
  "component---src-pages-implantes-js": () => import("./../../../src/pages/implantes.js" /* webpackChunkName: "component---src-pages-implantes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instituto-js": () => import("./../../../src/pages/instituto.js" /* webpackChunkName: "component---src-pages-instituto-js" */),
  "component---src-pages-intoleranciaalimentar-js": () => import("./../../../src/pages/intoleranciaalimentar.js" /* webpackChunkName: "component---src-pages-intoleranciaalimentar-js" */),
  "component---src-pages-intradermoterapia-js": () => import("./../../../src/pages/intradermoterapia.js" /* webpackChunkName: "component---src-pages-intradermoterapia-js" */),
  "component---src-pages-jatodeplasma-js": () => import("./../../../src/pages/jatodeplasma.js" /* webpackChunkName: "component---src-pages-jatodeplasma-js" */),
  "component---src-pages-lipodepapada-js": () => import("./../../../src/pages/lipodepapada.js" /* webpackChunkName: "component---src-pages-lipodepapada-js" */),
  "component---src-pages-manthus-js": () => import("./../../../src/pages/manthus.js" /* webpackChunkName: "component---src-pages-manthus-js" */),
  "component---src-pages-microagulhamento-js": () => import("./../../../src/pages/microagulhamento.js" /* webpackChunkName: "component---src-pages-microagulhamento-js" */),
  "component---src-pages-oligoscan-js": () => import("./../../../src/pages/oligoscan.js" /* webpackChunkName: "component---src-pages-oligoscan-js" */),
  "component---src-pages-ozoniosistemico-js": () => import("./../../../src/pages/ozoniosistemico.js" /* webpackChunkName: "component---src-pages-ozoniosistemico-js" */),
  "component---src-pages-ozonioterapia-js": () => import("./../../../src/pages/ozonioterapia.js" /* webpackChunkName: "component---src-pages-ozonioterapia-js" */),
  "component---src-pages-peelingdediamante-js": () => import("./../../../src/pages/peelingdediamante.js" /* webpackChunkName: "component---src-pages-peelingdediamante-js" */),
  "component---src-pages-peelingquimico-js": () => import("./../../../src/pages/peelingquimico.js" /* webpackChunkName: "component---src-pages-peelingquimico-js" */),
  "component---src-pages-preenchimentolabial-js": () => import("./../../../src/pages/preenchimentolabial.js" /* webpackChunkName: "component---src-pages-preenchimentolabial-js" */),
  "component---src-pages-prp-js": () => import("./../../../src/pages/prp.js" /* webpackChunkName: "component---src-pages-prp-js" */),
  "component---src-pages-riffemattron-js": () => import("./../../../src/pages/riffemattron.js" /* webpackChunkName: "component---src-pages-riffemattron-js" */),
  "component---src-pages-scannerbiocorporal-js": () => import("./../../../src/pages/scannerbiocorporal.js" /* webpackChunkName: "component---src-pages-scannerbiocorporal-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-skinbooster-js": () => import("./../../../src/pages/skinbooster.js" /* webpackChunkName: "component---src-pages-skinbooster-js" */),
  "component---src-pages-terapianeural-js": () => import("./../../../src/pages/terapianeural.js" /* webpackChunkName: "component---src-pages-terapianeural-js" */),
  "component---src-pages-terapiasinjetaveis-js": () => import("./../../../src/pages/terapiasinjetaveis.js" /* webpackChunkName: "component---src-pages-terapiasinjetaveis-js" */),
  "component---src-pages-testedeparasitas-js": () => import("./../../../src/pages/testedeparasitas.js" /* webpackChunkName: "component---src-pages-testedeparasitas-js" */),
  "component---src-pages-testegenetico-js": () => import("./../../../src/pages/testegenetico.js" /* webpackChunkName: "component---src-pages-testegenetico-js" */),
  "component---src-pages-toxinabotulinica-js": () => import("./../../../src/pages/toxinabotulinica.js" /* webpackChunkName: "component---src-pages-toxinabotulinica-js" */),
  "component---src-pages-vonardenne-js": () => import("./../../../src/pages/vonardenne.js" /* webpackChunkName: "component---src-pages-vonardenne-js" */)
}

